import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faQuestionCircle, faBook } from '@fortawesome/free-solid-svg-icons';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header>
        <h1>Deeply</h1>
      </header>

      <main>
        <h2 className="main-text">Learn like you mean it</h2>
        
        <div className="product-boxes">
          <div className="box">
            <FontAwesomeIcon icon={faPencilAlt} />
            <span>Deeply Write</span>
            <p className="product-description">Detailed feedback on your academic writing</p>
          </div>
          <div className="box">
            <FontAwesomeIcon icon={faQuestionCircle} />
            <span>Deeply Quiz</span>
            <p className="product-description">Never forget what you learn</p>
          </div>
          <div className="box">
            <FontAwesomeIcon icon={faBook} />
            <span>Deeply Grammar</span>
            <p className="product-description">Mastering grammar has never been more fun</p>
          </div>
        </div>
      </main>

      <footer>
        <p>&copy; 2023 Deeply. All rights reserved.</p>
        <nav>
          <a href="#">Privacy Policy</a>
          <a href="#">Terms of Service</a>
          <a href="#">Contact Us</a>
        </nav>
      </footer>
    </div>
  );
};

export default LandingPage;
